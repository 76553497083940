import React from "react";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import { useHistory } from "react-router-dom";

let logo = "/img/common/final.png";

export default function Navbar() {
  const history = useHistory();

  return (
    <div className="navbar-div">
      <Link to="/">
        <img
          src={logo}
          alt="Innoventic FZCO logo"
          className="innoventic-logo"
        />
      </Link>
      {/*      <ul className="navbar-ul">
        <Link to="/">
          <li>Home</li>
        </Link>
        <Link to="/">
          <li>Products</li>
        </Link>
        <Link to="/">
          <li>Contact Us</li>
        </Link>
      </ul>
  */}
      <Scrollspy items={["home", "product", "contact"]}>
        <ul className="navbar-ul">
          <li className={history.location.hash === "#home" ? "is-current" : ""}>
            <a href="#home">Home</a>
          </li>
          <li
            className={history.location.hash === "#product" ? "is-current" : ""}
          >
            <a href="#product">Product</a>
          </li>
          <li
            className={history.location.hash === "#contact" ? "is-current" : ""}
          >
            <a href="#contact">Contact Us</a>
          </li>
        </ul>
      </Scrollspy>
    </div>
  );
}
