import React, { useState } from 'react';
import axios from 'axios';

let arrow = '/img/icons/arrow.png';
let img1 = '/img/contact/big-sqaure.png';
let img2 = '/img/contact/small-sqaure.png';

export default function ContactForm(props) {
    const [values, setValues] = useState({
        fName: '',
        email: '',
        pNumber: '',
        location: '',
        message: ''
    });

    /*==========================
             handler
 ===========================*/

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleServerResponse = (ok, msg, form) => {
        if (ok) {
            form.reset();
            props.setEmailModal(true);
            setTimeout(() => {
                props.setEmailModal(false);
            }, 1000);
        } else {
            props.setFailModal(true);
            setTimeout(() => {
                props.setEmailModal(false);
            }, 1000);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(values);
        const form = e.target;
        console.log(form);
        console.log('test');

        axios({
            method: 'post',
            url: 'https://formspree.io/f/xnqwdkeb',
            data: new FormData(form)
        })
            .then((r) => {
                handleServerResponse(true, 'Thanks!', form);
            })
            .catch((r) => {
                handleServerResponse(false, r.response.data.error, form);
            });
    };

    return (
        <div className="contact-form-outer-div">
            <div className="contact-form-small-square-div">
                <img
                    src={img2}
                    alt="gradient"
                    className="contact-form-small-square-img"
                />
            </div>
            <div className="contact-form-div">
                <form onSubmit={handleSubmit}>
                    <div className="contact-input-div">
                        <input
                            placeholder="Name"
                            name="fName"
                            value={values.fName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="contact-input-div pt-25">
                        <input
                            placeholder="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            type="email"
                            required
                        />
                    </div>
                    <div className="contact-input-div pt-25">
                        <input
                            placeholder="Phone Number"
                            name="pNumber"
                            pattern="[0-9]*"
                            maxLength="10"
                            value={values.pNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="contact-input-div pt-25">
                        <input
                            placeholder="Location"
                            name="location"
                            value={values.location}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="contact-input-div contact-input-div--textarea pt-25">
                        <textarea
                            placeholder="Your Message"
                            name="message"
                            value={values.message}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button
                        onSubmit={handleSubmit}
                        type="submit"
                        className="contact-submit-btn"
                    >
                        Submit
                        <img
                            src={arrow}
                            alt="arrow"
                            className="contact-arrow-img"
                        />
                    </button>
                </form>
                <div className="contact-form-big-square-div">
                    <img
                        src={img1}
                        alt="gradient"
                        className="contact-form-big-square-img"
                    />
                </div>
            </div>
        </div>
    );
}
