import "./App.scss";
//  bootstrap file
import "bootstrap/dist/css/bootstrap.min.css";
//  fontawesome file
import "font-awesome/css/font-awesome.min.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/home/Home";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
