import React from "react";
import ProductSlider from "./ProductSlider";

export default function Product() {
  return (
    <section id="product">
      <div className="product-div" id="product">
        <h3 className="font-36-brandon-bold font-36-brandon-bold--product ">
          Products &amp;
        </h3>
        <h2 className="font-64-circular font-64-circular--product">
          Industries We Serve
        </h2>
        {/*<h5 className="font-24-brandon-light font-24-brandon-light--product">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna
        <br /> aliqua. Ut enim ad minim
  </h5>*/}
        <ProductSlider />
      </div>
    </section>
  );
}
