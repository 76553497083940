import React from "react";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

let p1 = "/img/product/p1.png";
let p2 = "/img/product/p2.png";
let p3 = "/img/product/p3.png";
let p4 = "/img/product/p4.png";
let p5 = "/img/product/p5.png";

const productData = [
  {
    imgPath: p1,
    name: "Lubricants",
  },
  {
    imgPath: p2,
    name: "Foam",
  },
  {
    imgPath: p3,
    name: "Paints & Coating",
  },
  {
    imgPath: p4,
    name: "Detergents",
  },
  {
    imgPath: p5,
    name: "Home and Personal Care",
  },
];

export default function ProductSlider() {
  const renderList1 = () => {
    return (
      <div className="product-slider-right-text">
        <ul>
          <li>Glycols</li>
        </ul>
      </div>
    );
  };

  const renderList2 = () => {
    return (
      <div className="product-slider-right-text">
        <ul>
          <li>MDI M200</li>
          <li>Polyols</li>
        </ul>
      </div>
    );
  };

  const renderList3 = () => {
    return (
      <div className="product-slider-right-text">
        <ul>
          <li>Epoxy Resin</li>
          <li>Reactive Diluents</li>
          <li>Titanium Dioxide</li>
          <li>Additives</li>
        </ul>
      </div>
    );
  };

  const renderList4 = () => {
    return (
      <div className="product-slider-right-text">
        <ul>
          <li>
            Optical <br />
            Brighteners
          </li>
          <li>Enzymes</li>
        </ul>
      </div>
    );
  };

  const renderList5 = () => {
    return (
      <div className="product-slider-right-text">
        <ul>
          <li>Glycols</li>
        </ul>
      </div>
    );
  };

  const renderSlider = (index) => {
    return (
      <div className="product-slider-card">
        <div className="product-slider-card-img-div">
          <img
            src={productData[index].imgPath}
            alt={productData[index].name}
            //className="img-fluid"
          />
        </div>
        <h3 className="font-36-circular font-36-circular--product-slider-text">
          {productData[index].name}
        </h3>
        <div className="row mx-0 align-items-start flex-nowrap justify-content-center">
          <h5 className="font-24-brandon-bold font-24-brandon-bold--product-slider-text">
            Products:
          </h5>

          {index === 0 ? (
            renderList1()
          ) : index === 1 ? (
            renderList2()
          ) : index === 2 ? (
            renderList3()
          ) : index === 3 ? (
            renderList4()
          ) : index === 4 ? (
            renderList5()
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="product-slider-div">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={170}
        totalSlides={10}
        interval={4000}
        isPlaying={true}
        step={1}
        infinite={true}
        currentSlide={0}
        visibleSlides={4.2}
        playDirection={"forward"}
      >
        <Slider>
          <Slide index={0}>{renderSlider(0)}</Slide>
          <Slide index={1}>{renderSlider(1)}</Slide>
          <Slide index={2}>{renderSlider(2)}</Slide>
          <Slide index={3}>{renderSlider(3)}</Slide>
          <Slide index={4}>{renderSlider(4)}</Slide>
          <Slide index={5}>{renderSlider(0)}</Slide>
          <Slide index={6}>{renderSlider(1)}</Slide>
          <Slide index={7}>{renderSlider(2)}</Slide>
          <Slide index={8}>{renderSlider(3)}</Slide>
          <Slide index={9}>{renderSlider(4)}</Slide>
        </Slider>
      </CarouselProvider>
    </div>
  );
}
