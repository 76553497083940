import React from 'react';
import ContactDetails from './ContactDetails';
import ContactForm from './ContactForm';

export default function Contact(props) {
    return (
        <section id="contact">
            <div className="contact-div">
                <h2 className="text-center font-64-circular font-64-circular--contact">
                    Contact Us
                </h2>
                <div className="row mx-0 align-items-start flex-nowrap">
                    <ContactDetails />
                    <ContactForm
                        setEmailModal={props.setEmailModal}
                        setFailModal={props.setFailModal}
                    />
                </div>
            </div>
        </section>
    );
}
