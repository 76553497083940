import React from "react";

let location = "/img/icons/location.png";
let call = "/img/icons/call.png";
let mail = "/img/icons/mail.png";
let fax = "/img/icons/fax.png";
let add = "/img/icons/add.png";

const contactArray = [
  {
    imgPath: location,
    imgClass: "contact-location-img",
    text: "Innoventic FZCO, 1 37a Street Dubai, UAE",
  },
  {
    imgPath: call,
    imgClass: "contact-call-img",
    text: "+971-4-2236409, +971-52-9054113",
  },
  {
    imgPath: mail,
    imgClass: "contact-mail-img",
    text: " sales@innoventic.org",
  },
  {
    imgPath: fax,
    imgClass: "contact-fax-img",
    text: "+971-4-2271033",
  },
  {
    imgPath: add,
    imgClass: "contact-add-img",
    text: "P.O Box No : 7800",
  },
];

export default function ContactDetails() {
  return (
    <div className="contact-details-div">
      <div>
        {contactArray.map((data, index) => (
          <div
            key={index}
            className="row mx-0 align-items-center flex-nowrap pt-60"
          >
            <div className="contact-details-img-div">
              <img
                src={data.imgPath}
                alt="location"
                className={data.imgClass}
              />
            </div>
            <h5 className="font-19-brandon-normal font-19-brandon-normal--contact">
              {data.text}
            </h5>
          </div>
        ))}
      </div>
    </div>
  );
}
