import React, { useEffect, useState } from 'react';
import Contact from '../contact/Contact';
import Footer from '../header-footer/Footer';
import Product from '../product/Product';
import HomeFoldOne from './HomeFoldOne';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Home() {
    const [emailModal, setEmailModal] = useState(false);
    const [failModal, setFailModal] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        if (failModal) {
            toast.error(`Try again, the email didn't go through`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    }, [failModal]);

    useEffect(() => {
        if (emailModal) {
            toast.info('Your email has been sent!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    }, [emailModal]);

    return (
        <>
            <ToastContainer />
            <HomeFoldOne />
            <Product />
            <Contact
                setEmailModal={setEmailModal}
                setFailModal={setFailModal}
            />
            <Footer />
        </>
    );
}
