import React from "react";
import Navbar from "../header-footer/Navbar";

export default function HomeFoldOne() {
  return (
    <>
      <div className="home-div">
        <Navbar />
        <div className="home-content-div">
          <h4 className="home-font-36-brandon-bold font-36-brandon-bold">
            Welcome to
          </h4>
          <h1 className="home-font-64-circular font-64-circular">
            Innoventic FZCO
          </h1>
          <p className="home-font-24-brandon-normal font-24-crea-medium">
            Innoventic FZCO is a Chemical Distribution company set up in the
            U.A.E
            <br />
            and we specialize mainly in the distribution of chemical commodities
            and <br />
            specialty products within the MENA region.
          </p>
          <p className="home-font-24-brandon-normal font-24-crea-medium mb-0">
            With our Warehousing and Distribution infrastructure set up in Jebel
            Ali,
            <br /> we are well suited to distribute rapidly and efficiently to
            our customers.
          </p>
        </div>
      </div>
    </>
  );
}
