import React from "react";
import Scrollspy from "react-scrollspy";
import { Link, useHistory } from "react-router-dom";

// let logo = "/img/common/footer-logo.png";

let logo = "/img/common/final-footer.png";

export default function Footer() {
  const history = useHistory();

  return (
    <div className="footer-div">
      <Link to="/">
        <img src={logo} alt="footer logo" className="footer-logo-img" />
      </Link>
      <div className="text-center pt-25">
        <Scrollspy items={["product", "contact"]}>
          <ul className="footer-ul">
            <li
              className={
                history.location.hash === "#product" ? "is-current" : ""
              }
            >
              <a href="#product">Product</a>
            </li>
            <li
              className={
                history.location.hash === "#contact" ? "is-current" : ""
              }
            >
              <a href="#contact">Contact Us</a>
            </li>
          </ul>
        </Scrollspy>
      </div>
    </div>
  );
}
